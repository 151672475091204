import baseService from '../baseService';
import enumService from './enum';
import { array_find, stringNumToBoolean } from '@/utils/methods/common';
const request = baseService('resource');

export default {
  getResourceTree(params) {
    return Promise.all([
      request('/getResourceTree', params, 'get'),
      enumService('resourceType')()
    ]).then((res) => {
      const loopTree = (list) => {
        list.forEach((item) => {
          item.type_cn = array_find(res[1], 'value', item.type).label;
          item.blank_bn = stringNumToBoolean(item.blank);
          item.enable_bn = stringNumToBoolean(item.enable);
          item.visible_bn = stringNumToBoolean(item.visible);
          item.homePath_bn = stringNumToBoolean(item.homePath);
          if (Array.isArray(item.children)) {
            loopTree(item.children);
          }
        });
      };
      loopTree(res[0].list);
      return res[0];
    });
  },
  deleteResources(params) {
    return request('/deleteResources', params, 'delete');
  },
  getResourceDetail(params) {
    return request('/getResourceDetail', params, 'get');
  },
  addResource(params) {
    return request('/addResource', params, 'post');
  },
  editResource(params) {
    return request('/editResource', params, 'put');
  },
  quickOperateResource(params) {
    return request('/quickOperateResource', params, 'put');
  },
  exportResource(params) {
    return request('/exportResource', params, 'download_post');
  },
  importResource(params) {
    return request('/importResource', params);
  },
  getResourceBtnList(params) {
    return request('/getResourceBtnList', params, 'get').then((res) => {
      res.list.forEach((item) => {
        item.enable_bn = stringNumToBoolean(item.enable);
      });
      return res;
    });
  },
  quickOperateResourceBtn(params) {
    return request('/quickOperateResourceBtn', params, 'put');
  },
  getResourceBtnDetail(params) {
    return request('/getResourceBtnDetail', params, 'get');
  },
  addResourceBtn(params) {
    return request('/addResourceBtn', params, 'post');
  },
  editResourceBtn(params) {
    return request('/editResourceBtn', params, 'put');
  },
  deleteResourceBtns(params) {
    return request('/deleteResourceBtns', params, 'delete');
  }
};
