import baseService from '../baseService';
const request = baseService('role');

export default {
  getRoleList(params) {
    return request('/getRoleList', params, 'get');
  },
  addRole(params) {
    return request('/addRole', params, 'post');
  },
  editRole(params) {
    return request('/editRole', params, 'put');
  },
  getRoleDetail(params) {
    return request('/getRoleDetail', params, 'get');
  },
  getRoleUsers(params) {
    return request('/getRoleUsers', params, 'get');
  },
  setRoleUsers(params) {
    return request('/setRoleUsers', params, 'post');
  },
  getRoleResourceIds(params) {
    return request('/getRoleResourceIds', params, 'get');
  },
  getRoleResourceBtnIdsByResource(params) {
    return request('/getRoleResourceBtnIdsByResource', params, 'get');
  },
  getBatchRoleResourceIds(params) {
    return request('/getBatchRoleResourceIds', params, 'get');
  },
  getBatchRoleResourceBtnIdsByResource(params) {
    return request('/getBatchRoleResourceBtnIdsByResource', params, 'get');
  },
  deleteRoles(params) {
    return request('/deleteRoles', params, 'delete');
  },
  setRoleResourceIds(params) {
    return request('/setRoleResourceIds', params, 'post');
  },
  setRoleResourceBtnIdsByResource(params) {
    return request('/setRoleResourceBtnIdsByResource', params, 'post');
  }
};
