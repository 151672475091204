<template>
  <dog-dialog
    ref="dogDialog"
    width="600px"
    title="权限配置"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <div class="flex_row dialog_zone_scroll setResourcesDialog">
      <dog-scroll-box class="flex_1">
        <el-tree
          ref="resourceTree"
          :data="resourceData"
          :props="resourceProps"
          show-checkbox
          node-key="id"
          :default-checked-keys="defaultCheckedKeys"
          highlight-current
          :expand-on-click-node="false"
          :default-expand-all="true"
          @node-click="handleNodeClick"
          @check="checkResource"
        ></el-tree>
      </dog-scroll-box>
      <el-divider direction="vertical"></el-divider>
      <dog-scroll-box class="flex_1">
        <el-tree
          ref="resourceBtnTree"
          class="flex_1"
          :data="resourceBtnData"
          :props="resourceBtnProps"
          show-checkbox
          node-key="id"
          :default-checked-keys="defaultCheckedKeysBtn"
          :default-expand-all="true"
          @check="checkResourceBtn"
        ></el-tree>
      </dog-scroll-box>
    </div>
  </dog-dialog>
</template>

<script>
  import roleService from '@/api/service/role.js';
  import resourceService from '@/api/service/resource';

  export default {
    name: 'setResourcesDialog',
    data() {
      return {
        roleId: null,
        currentResourceId: null,
        resourceData: [],
        resourceProps: {
          label: 'title'
        },
        defaultCheckedKeys: [],
        resourceBtnData: [],
        resourceBtnProps: {
          label: 'name'
        },
        defaultCheckedKeysBtn: [],
        checkResourceBtnIds: {}
      };
    },
    methods: {
      openFrame(roleId) {
        this.roleId = roleId;
        this.$refs.dogDialog.open();
        resourceService.getResourceTree().then((res) => {
          this.resourceData = res.list;
        });
        roleService.getRoleResourceIds({ roleId }).then((res) => {
          this.defaultCheckedKeys = res;
        });
      },
      handleNodeClick(data) {
        // 目录不展示按钮
        if (data.children && data.children.length > 0) {
          this.resourceBtnData = [];
          this.defaultCheckedKeysBtn = [];
          this.currentResourceId = null;
          return;
        }
        this.currentResourceId = data.id;
        this.getResourceBtnList();
      },
      getResourceBtnList() {
        if (!this.currentResourceId) {
          return;
        }
        const checkResourceIds = this.$refs.resourceTree.getCheckedKeys(true);
        resourceService
          .getResourceBtnList({ resourceId: this.currentResourceId })
          .then((res) => {
            this.resourceBtnData = [
              {
                name: '全选',
                id: 0,
                disabled: !checkResourceIds.includes(this.currentResourceId),
                // 未选择的资源，按钮不可选
                children: checkResourceIds.includes(this.currentResourceId)
                  ? res.list
                  : res.list.map((item) => {
                      return {
                        ...item,
                        disabled: true
                      };
                    })
              }
            ];
          });
        //  如果操作过，取操作后的值
        if (this.checkResourceBtnIds[this.currentResourceId]) {
          this.defaultCheckedKeysBtn = this.checkResourceBtnIds[
            this.currentResourceId
          ];
        } else {
          roleService
            .getRoleResourceBtnIdsByResource({
              roleId: this.roleId,
              resourceId: this.currentResourceId
            })
            .then((res) => {
              this.defaultCheckedKeysBtn = res;
              this.checkResourceBtnIds[this.currentResourceId] = res;
            });
        }
      },
      checkResource(data, checked) {
        // 取消选择目录时，需要遍历目录下的菜单，按钮选择重置为空
        if (data.children && data.children.length > 0) {
          if (!checked.checkedKeys.includes(data.id)) {
            const clearResources = (resources) => {
              resources.forEach((item) => {
                if (!item.children || item.children.length === 0) {
                  this.checkResourceBtnIds[item.id] = [];
                } else {
                  clearResources(item.children);
                }
              });
            };
            clearResources(data.children);
          }
          this.getResourceBtnList();
        } else {
          // 取消选择菜单时，只需要把当前菜单按钮选择置空
          if (!checked.checkedKeys.includes(data.id)) {
            this.checkResourceBtnIds[data.id] = [];
          }
          this.getResourceBtnList();
        }
      },
      // 保存所选按钮
      checkResourceBtn(data, checked) {
        this.checkResourceBtnIds[
          this.currentResourceId
        ] = checked.checkedKeys.filter((item) => {
          // 过滤全选
          return item !== 0;
        });
      },
      confirm(done) {
        const checkResourceIds = this.$refs.resourceTree.getCheckedKeys(true);
        // const checkResourceBtnIds = Object.keys(
        //   this.checkResourceBtnIds
        // ).reduce((total, item) => {
        //   return total.concat(this.checkResourceBtnIds[item]);
        // }, []);
        const request =
          Object.keys(this.checkResourceBtnIds).length > 0
            ? [
                roleService.setRoleResourceBtnIdsByResource({
                  roleId: this.roleId,
                  resourceBtnIdsOfResource: this.checkResourceBtnIds
                }),
                roleService.setRoleResourceIds({
                  roleId: this.roleId,
                  resourceIds: checkResourceIds
                })
              ]
            : [
                roleService.setRoleResourceIds({
                  roleId: this.roleId,
                  resourceIds: checkResourceIds
                })
              ];
        Promise.all(request)
          .then(() => {
            this.$message.success('保存成功！');
            done();
            this.$emit('confirm');
            this.close();
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.roleId = null;
        this.currentResourceId = null;
        this.resourceData = [];
        this.defaultCheckedKeys = [];
        this.resourceBtnData = [];
        this.defaultCheckedKeysBtn = [];
        this.checkResourceBtnIds = {};
      }
    }
  };
</script>

<style lang="scss" scoped></style>
